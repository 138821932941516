import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

// Initialize Sentry using the environment variable MIX_SENTRY_DSN_JS
if (process.env.MIX_SENTRY_DSN_JS) {
    Sentry.init({
        dsn: process.env.MIX_SENTRY_DSN_JS,
        integrations: [
            new Integrations.BrowserTracing()
        ],
        tracesSampleRate: 1.0, // Adjust this value in production for better performance
    });
}

require('./bootstrap');
window.Jquery = require('jquery');

$(function () {
    $("form").on('submit', function () {
        $('.modal').hide();
        $('#loader').show();
    });
});

$(window).on('popstate', function (event) {
    $('#loader').hide();
});

jQuery(function () {
    // Capture scroll position before clicking pagination link
    $(document).on('click', '.pagination a', function (event) {
        sessionStorage.setItem('scrollPosition', $(window).scrollTop());
    });


    // Restore scroll position if it's set
    if (sessionStorage.getItem('scrollPosition')) {
        var scrollPosition = sessionStorage.getItem('scrollPosition');
        $(window).scrollTop(scrollPosition);
        sessionStorage.removeItem('scrollPosition');
    }
});

